import {
  createPromiseClient,
  Interceptor,
  PromiseClient,
} from "@bufbuild/connect";
import { createConnectTransport } from "@bufbuild/connect-web";
import { ServiceType } from "@bufbuild/protobuf";
import { Auth } from "firebase/auth";
import { useMemo } from "react";

import { NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH } from "../../constants/env";
import { useFirebase } from "../../contexts/firebase";

const authInterceptor: (auth: Auth) => Interceptor =
  (auth) => (next) => async (req) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      if (token !== undefined) {
        req.header.set("Authorization", `Bearer ${token}`);
      }
    } catch (err) {
      console.error(err);
    }

    return await next(req);
  };

/**
 * Connectクライアントを生成する
 * - 主に getServerSideProps で利用する
 * - 通常は {@link useConnectClient} を利用する
 *
 * @example
 * // 認証情報を含めたい場合
 * const clientWithAuth = createConnectClient(auth)(ExampleService)
 * await clientWithAuth.exampleMethod({ exampleField: "example" })
 *
 * // 認証情報を含めない場合（getServerSidePropsなどで利用する）
 * const clientWithoutAuth = createConnectClient(undefined)(ExampleService)
 * await clientWithoutAuth.exampleMethod({ exampleField: "example" })
 */
export const createConnectClient =
  (auth: Auth | undefined) =>
  <T extends ServiceType>(service: T): PromiseClient<T> => {
    return createPromiseClient(
      service,
      createConnectTransport({
        baseUrl: NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH,
        useBinaryFormat: process.env.NODE_ENV === "production",
        useHttpGet: true,
        credentials: "same-origin",
        interceptors: auth ? [authInterceptor(auth)] : [],
      })
    );
  };

/**
 * FirebaseContextを利用してConnectクライアントを生成する
 * - currentUserが存在する場合、AuthorizationヘッダーにBearerトークンをセットする
 *
 * @example
 * const client = useConnectClient(ExampleService)
 * await client.exampleMethod({ exampleField: "example" })
 */
export const useConnectClient = <T extends ServiceType>(
  service: T
): PromiseClient<T> => {
  const {
    firebase: { auth },
  } = useFirebase();

  const client = useMemo(
    () => createConnectClient(auth)<T>(service),
    [service, auth]
  );

  return client;
};
